.achievements-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
    padding: 20px;
  }

  .achievement {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .achievement.locked {
    filter: grayscale(100%);
  }

  .achievement:hover {
    transform: scale(1.05);
  }

  .achievement-image-container {
    position: relative;
    width: 150px;
    height: 150px;
  }

  .achievement-container {
    padding: 20px;
  }

  .achievement img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .lock-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lock-icon::before {
    content: '';
    width: 10px;
    height: 7px;
    border: 2px solid white;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    position: absolute;
    top: 4px;
  }

  .lock-icon::after {
    content: '';
    width: 14px;
    height: 10px;
    background-color: white;
    border-radius: 3px;
    position: absolute;
    bottom: 4px;
  }

  .achievement-name {
    margin-top: 8px;
    font-size: 14px;
    text-align: center;
  }

  .achievement::after {
    content: attr(data-description);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .achievement:hover::after {
    opacity: 1;
    visibility: visible;
  }