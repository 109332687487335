/* Base styles */
:root {
    --primary-color: #4f46e5;
    --primary-hover: #4338ca;
    --background-color: #ffffff;
    --border-color: #e5e7eb;
    --text-color: #111827;
    --text-muted: #6b7280;
    --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --radius-sm: 0.25rem;
    --radius-md: 0.375rem;
    --radius-lg: 0.5rem;
    --radius-xl: 0.75rem;
  }
  
  /* Card styles */
  .filter-card {
    background-color: var(--background-color);
    border-radius: var(--radius-xl);
    box-shadow: var(--shadow-lg);
    width: 100%;
    overflow: hidden;
  }
  
  .filter-card-header {
    padding: 1.25rem 1.5rem 0.75rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .filter-card-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--text-color);
    margin: 0;
  }
  
  .filter-card-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 450px;
  }
  
  .filter-card-footer {
    padding: 1rem 1.5rem;
    border-top: 1px solid var(--border-color);
  }
  
  /* Group styles */
  .filter-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
  }
  
  .filter-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-color);
  }
  
  /* Select styles */
  .filter-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: var(--radius-md);
    background-color: var(--background-color);
    cursor: pointer;
    min-height: 2.5rem;
    transition: border-color 0.15s ease, box-shadow 0.15s ease;
  }
  
  .filter-select:hover {
    border-color: #d1d5db;
  }
  
  .filter-select.active {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 1px var(--primary-color);
  }
  
  .filter-select-value {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    max-width: calc(100% - 1.5rem);
    overflow: hidden;
  }
  
  .filter-placeholder {
    color: var(--text-muted);
  }
  
  .filter-select-icon {
    color: var(--text-muted);
    transition: transform 0.2s ease;
  }
  
  .filter-select-icon.rotate {
    transform: rotate(180deg);
  }
  
  /* Badge styles */
  .filter-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }
  
  .filter-badge {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    background-color: #f3f4f6;
    border-radius: 9999px;
    padding: 0.25rem 0.5rem 0.25rem 0.75rem;
    font-size: 0.75rem;
  }
  
  .filter-badge-text {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .filter-badge-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: var(--text-muted);
    transition: color 0.15s ease;
  }
  
  .filter-badge-remove:hover {
    color: var(--text-color);
  }
  
  /* Dropdown styles */
  .filter-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 0.25rem;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: var(--radius-md);
    box-shadow: var(--shadow-md);
    z-index: 10;
    max-height: 15rem;
    overflow-y: auto;
  }
  
  .filter-search {
    padding: 0.5rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .filter-search-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: var(--radius-sm);
    font-size: 0.875rem;
  }
  
  .filter-search-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 1px var(--primary-color);
  }
  
  .filter-dropdown-items {
    padding: 0.25rem 0;
  }
  
  .filter-dropdown-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    transition: background-color 0.15s ease;
  }
  
  .filter-dropdown-item:hover {
    background-color: #f9fafb;
  }
  
  .filter-dropdown-text {
    font-size: 0.875rem;
  }
  
  /* Checkbox styles */
  .filter-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    background-color: var(--background-color);
    transition: background-color 0.15s ease, border-color 0.15s ease;
  }
  
  .filter-checkbox.checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: white;
  }
  
  /* Divider */
  .filter-divider {
    height: 1px;
    background-color: var(--border-color);
    margin: 0.25rem 0;
  }
  
  /* Date grid */
  .filter-date-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  @media (min-width: 640px) {
    .filter-date-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  /* Native select */
  .filter-native-select {
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: var(--radius-md);
    background-color: var(--background-color);
    font-size: 0.875rem;
    color: var(--text-color);
    width: 100%;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236b7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1rem;
  }
  
  .filter-native-select:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 1px var(--primary-color);
  }
  
  /* Checkbox group */
  .filter-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .filter-checkbox-item {
    display: flex;
    align-items: center;
  }
  
  .filter-checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }
  
  .filter-checkbox-input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .filter-checkbox-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    background-color: var(--background-color);
    transition: background-color 0.15s ease, border-color 0.15s ease;
  }
  
  .filter-checkbox-input:checked + .filter-checkbox-custom {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: white;
  }
  
  .filter-checkbox-text {
    font-size: 0.875rem;
  }
  
  /* Apply button */
  .filter-apply-button {
    width: 100%;
    padding: 0.625rem 1.25rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: var(--radius-md);
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.15s ease;
  }
  
  .filter-apply-button:hover {
    background-color: var(--primary-hover);
  }
  
  