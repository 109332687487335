@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.profile-card {
  width: 100%;
  max-width: 360px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
}

.profile-header {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 32px 24px;
  text-align: center;
  color: #ffffff;
}

.profile-image-container {
  width: 100px;
  height: 100px;
  margin: 0 auto 20px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-name {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 4px;
}

.profile-title {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9;
  margin: 0;
}

.profile-body {
  padding: 24px;
}

.profile-contact {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
}

.contact-link {
  display: flex;
  align-items: center;
  color: #4a5568;
  text-decoration: none;
  transition: color 0.2s ease;
}

.contact-link:hover {
  color: #667eea;
}

.contact-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.contact-text {
  font-size: 14px;
}

.profile-xp {
  background-color: #f7fafc;
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.xp-label {
  font-size: 14px;
  font-weight: 600;
  color: #4a5568;
}

.xp-value {
  font-size: 16px;
  font-weight: 700;
  color: #667eea;
}

.profile-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.badge {
  font-size: 12px;
  font-weight: 500;
  color: #4a5568;
  background-color: #edf2f7;
  padding: 4px 10px;
  border-radius: 20px;
  transition: all 0.2s ease;
}

.badge:hover {
  background-color: #667eea;
  color: #ffffff;
}

