.achievment-pages {
    display: grid;
    align-items: start;
    gap: 20px;
}


.achievment-user-data {
    display: grid;
    gap: 20px;
}