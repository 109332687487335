.hv-client-card {
    background-color: white;
    position: relative;
    padding: 1.5rem;
    border-radius: var(--hv-radius);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform var(--hv-transition), box-shadow var(--hv-transition);
  }
  
  .hv-client-card:hover {
    transform: translateY(-4px);
    box-shadow: var(--hv-shadow-md);
  }
  
  .hv-client-card-header {
    margin-bottom: 1.5rem;
  }
  
  .hv-client-status-wrapper {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1rem;
  }
  
  .hv-client-status {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
  }
  
  .hv-status-active {
    background-color: var(--hv-color-primary-light);
    color: var(--hv-color-primary);
  }
  
  .hv-status-inactive {
    background-color: var(--hv-color-hover);
    color: var(--hv-color-text-light);
  }
  
  .hv-manager-badge {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
    background-color: #fef3c7;
    color: #d97706;
  }
  
  .hv-status-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  
  .hv-client-code {
    font-size: 0.75rem;
    color: var(--hv-color-text-light);
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .hv-client-name {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--hv-color-text);
    margin: 0 0 0.5rem 0;
    cursor: pointer;
  }
  
  .hv-project-name {
    font-size: 1rem;
    font-weight: 500;
    color: var(--hv-color-text-light);
    margin: 0;
  }
  
  .hv-client-details {
    margin-bottom: 1.5rem;
  }
  
  .hv-detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--hv-color-border);
  }
  
  .hv-detail-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--hv-color-text-light);
    font-size: 0.875rem;
  }
  
  .hv-detail-value {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--hv-color-text);
  }
  
  .hv-icon {
    width: 1rem;
    height: 1rem;
    color: var(--hv-color-primary);
  }
  
  .hv-tasks-section {
    margin-bottom: 1rem;
  }
  
  .hv-tasks-toggle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    background-color: var(--hv-color-hover);
    border: 1px solid var(--hv-color-border);
    border-radius: var(--hv-radius-sm);
    color: var(--hv-color-text);
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color var(--hv-transition);
  }
  
  .hv-tasks-toggle:hover {
    background-color: var(--hv-color-border);
  }
  
  .hv-chevron-icon {
    width: 1rem;
    height: 1rem;
    transition: transform var(--hv-transition);
  }
  
  .hv-chevron-expanded {
    transform: rotate(180deg);
  }
  
  .hv-tasks-grid {
    display: grid;
    gap: 0.5rem;
    margin-top: 0.75rem;
    padding: 0.5rem;
    background-color: var(--hv-color-hover);
    border-radius: var(--hv-radius-sm);
  }
  
  .hv-task-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    background-color: var(--hv-color-background);
    border: 1px solid var(--hv-color-border);
    border-radius: var(--hv-radius-sm);
    font-size: 0.875rem;
  }
  
  .hv-task-icon {
    width: 1rem;
    height: 1rem;
    color: var(--hv-color-primary);
  }
  
  .hv-task-inactive {
    margin-left: auto;
    font-size: 0.75rem;
    color: var(--hv-color-text-light);
    background-color: var(--hv-color-hover);
    padding: 0.125rem 0.375rem;
    border-radius: 9999px;
  }
  
  .hv-card-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--hv-color-border);
    font-size: 0.75rem;
    color: var(--hv-color-text-light);
  }
  
  