/* Reset some default styles */
.tabs-container {
    min-height: 100vh;
    display: flex;
    align-items: start;
    justify-content: center;
    width: 100%;
    transition: background-color 0.2s ease;
    margin-top: -10px;
  }
  
  .tabs-wrapper {
    width: 100%;
  }
  
  /* Tabs navigation */
  .tabs-nav {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    position: relative;
    z-index: 10;
  }
  
  .tabs-list {
    display: flex;
    gap: 0.25rem;
    flex: 1;
    padding-top: 12px;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .tabs-list::-webkit-scrollbar {
    display: none;
  }
  
  .tab-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.5rem 0.5rem 0 0;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .tab-button-inner {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0px;
    border: none;
    border-radius: 0.5rem 0.5rem 0 0;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 16px;
    background: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .tab-change-name-input {
    border: none;
    font-size: 16px;
    pad: 5px;
    background: none;
  }
  
  .tab-button:hover:not(.active) {
    filter: brightness(95%);
  }
  
  .tab-button.active {
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .tab-button.active::after {
    content: "";
    position: absolute;
    height: 4px;
    left: 0;
    right: 0;
    bottom: -1px;
    background-color: inherit;
  }
  
  .default-tab {
    border-right-width: 1px;
    border-right-style: solid;
  }
  
  .close-icon {
    margin-left: 0.5rem;
    opacity: 0.6;
    transition: opacity 0.2s ease;
  }
  
  .close-icon:hover {
    opacity: 1;
  }
  
  .add-tab-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .add-tab-button:hover {
    filter: brightness(95%);
  }
  
  /* Content panel */
  .tab-content {
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    transition: background-color 0.2s ease;
  }
  
  .tab-panel {
    display: none;
  }
  
  .tab-panel.active {
    display: block;
  }
  
  /* Content styling */
  .content-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .content-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
  }
  
  .content-description {
    margin: 0;
    transition: color 0.2s ease;
  }
  
  .content-box {
    padding: 1rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease;
  }
  
  .content-box p {
    font-size: 0.875rem;
    margin: 0;
  }
  