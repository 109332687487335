/* Base styles with af- prefix */
:root {
    --af-primary-color: #0070f3;
    --af-primary-hover: #0060df;
    --af-secondary-color: #f5f5f5;
    --af-text-color: #333;
    --af-text-muted: #666;
    --af-border-color: #e0e0e0;
    --af-card-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    --af-border-radius: 8px;
    --af-transition: all 0.2s ease;
    --af-success-color: #10b981;
    --af-warning-color: #f59e0b;
    --af-danger-color: #ef4444;
  }
  
  .af-container {
    margin: 0 auto;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
    color: var(--af-text-color);
  }
  
  .af-title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  
  .af-description {
    color: var(--af-text-muted);
    margin-bottom: 32px;
  }
  
  /* Tabs */
  .af-tabs {
    width: 100%;
  }
  
  .af-tab-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    margin-bottom: 32px;
    border-bottom: 1px solid var(--af-border-color);
  }
  
  .af-tab-button {
    background: none;
    border: none;
    padding: 12px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: var(--af-transition);
    color: var(--af-text-muted);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-bottom: 2px solid transparent;
  }
  
  .af-tab-button.af-active {
    color: var(--af-primary-color);
    border-bottom: 2px solid var(--af-primary-color);
  }
  
  .af-tab-button:hover:not(.af-active) {
    color: var(--af-text-color);
    background-color: var(--af-secondary-color);
  }
  
  .af-tab-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .af-tab-content {
    display: none;
  }
  
  .af-tab-content.af-active {
    display: block;
  }
  
  /* Cards */
  .af-card {
    background: white;
    border-radius: var(--af-border-radius);
    box-shadow: var(--af-card-shadow);
    margin-bottom: 24px;
    overflow: hidden;
  }
  
  .af-card-header {
    padding: 20px;
    border-bottom: 1px solid var(--af-border-color);
  }
  
  .af-accordion-header {
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .af-accordion-header:hover {
    background-color: var(--af-secondary-color);
  }
  
  .af-accordion-header.af-expanded {
    background-color: var(--af-secondary-color);
  }
  
  .af-card-title {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }
  
  .af-card-description {
    color: var(--af-text-muted);
    margin: 4px 0 0 0;
    font-size: 14px;
  }
  
  .af-card-content {
    padding: 20px;
  }
  
  /* Accordion */
  .af-accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .af-accordion-content.af-expanded {
    max-height: 2000px; /* Large enough to show all content */
  }
  
  .af-accordion-icon {
    display: flex;
    align-items: center;
    margin-left: 8px;
    transition: transform 0.3s ease;
  }
  
  /* Summary View */
  .af-summary-item {
    margin-bottom: 32px;
  }
  
  .af-summary-label {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  
  .af-chart {
    margin-bottom: 20px;
  }
  
  .af-chart-row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  
  .af-chart-label {
    width: 150px;
    font-size: 14px;
    padding-right: 16px;
  }
  
  .af-chart-bar-container {
    flex: 1;
    display: flex;
    align-items: center;
    height: 30px;
  }
  
  .af-chart-bar {
    height: 30px;
    min-width: 4px;
    border-radius: 4px;
    transition: width 0.5s ease;
    position: relative;
  }
  
  .af-chart-value {
    margin-left: 8px;
    font-weight: 500;
  }
  
  .af-stat-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
    margin-top: 16px;
  }
  
  .af-stat-card {
    background-color: white;
    border: 1px solid var(--af-border-color);
    border-radius: var(--af-border-radius);
    padding: 16px;
    text-align: center;
  }
  
  .af-stat-value {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  
  .af-stat-label {
    font-size: 14px;
    color: var(--af-text-muted);
  }
  
  /* Question View */
  .af-question-card {
    margin-bottom: 24px;
  }
  
  .af-scroll-area {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .af-response-item {
    padding: 16px;
    border: 1px solid var(--af-border-color);
    border-radius: var(--af-border-radius);
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .af-response-content {
    flex: 1;
  }
  
  .af-response-value {
    font-weight: 500;
    margin-bottom: 4px;
  }
  
  .af-response-email {
    font-size: 14px;
    color: var(--af-text-muted);
  }
  
  .af-response-date {
    font-size: 14px;
    color: var(--af-text-muted);
    text-align: right;
  }
  
  /* Individual View */
  .af-individual-card {
    margin-bottom: 24px;
  }
  
  .af-individual-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .af-individual-info {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .af-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--af-primary-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
  }
  
  .af-response-meta {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }
  
  .af-response-id {
    font-size: 14px;
    font-weight: 500;
  }
  
  .af-completion-indicator {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
  }
  
  .af-completion-bar {
    width: 100px;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .af-completion-progress {
    height: 100%;
    background-color: var(--af-success-color);
    border-radius: 4px;
    transition: width 0.3s ease;
  }
  
  .af-completion-text {
    font-size: 12px;
    color: var(--af-text-muted);
  }
  
  .af-answer-item {
    margin-bottom: 16px;
  }
  
  .af-answer-label {
    font-size: 14px;
    color: var(--af-text-muted);
    margin-bottom: 4px;
  }
  
  .af-answer-value {
    font-weight: 500;
    margin-bottom: 8px;
  }
  
  .af-separator {
    height: 1px;
    background-color: var(--af-border-color);
    margin-top: 8px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .af-chart-label {
      width: 100px;
    }
  
    .af-individual-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
  
    .af-response-meta {
      align-items: flex-start;
      width: 100%;
      margin-top: 8px;
    }
  
    .af-completion-indicator {
      align-items: flex-start;
    }
  }
  
  @media (max-width: 480px) {
    .af-tab-button {
      font-size: 14px;
      padding: 8px;
    }
  
    .af-tab-icon {
      font-size: 16px;
    }
  
    .af-chart-row {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
    }
  
    .af-chart-label {
      width: 100%;
      margin-bottom: 4px;
    }
  
    .af-chart-bar-container {
      width: 100%;
    }
  
    .af-stat-cards {
      grid-template-columns: 1fr 1fr;
    }
  
    .af-response-item {
      flex-direction: column;
    }
  
    .af-response-date {
      text-align: left;
      margin-top: 8px;
    }
  }
  
  /* Collection Response Styles */
.af-collection-response {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.af-collection-field-response {
  display: flex;
  flex-wrap: wrap;
  gap: 4px 8px;
}

.af-collection-field-name {
  font-weight: 500;
  color: #495057;
}

.af-collection-field-value {
  color: #212529;
}

.af-nested-collection {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 12px;
  border-left: 2px solid #dee2e6;
}

.af-nested-field {
  display: flex;
  gap: 8px;
}

.af-nested-field-name {
  font-weight: 500;
  color: #6c757d;
}

.af-nested-field-value {
  color: #495057;
}

/* Responsive adjustments for collection responses */
@media (max-width: 768px) {
  .af-collection-field-response {
    flex-direction: column;
    gap: 4px;
  }
  
  .af-nested-collection {
    padding-left: 8px;
  }
}


/* Customer Creation Button */
.af-create-customer-btn {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-left: 12px;
  padding: 4px 8px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.af-create-customer-btn:hover {
  background-color: #3e8e41;
}

/* Modal Styles */
.af-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.af-modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.af-modal-header {
  padding: 16px 20px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.af-modal-header h3 {
  margin: 0;
  font-size: 18px;
}

.af-modal-close {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 4px;
}

.af-modal-content {
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
}

.af-modal-footer {
  padding: 16px 20px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

/* Customer Form Styles */
.af-customer-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.af-form-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.af-form-group label {
  font-size: 14px;
  color: #555;
}

.af-form-group input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.af-form-row {
  display: flex;
  gap: 16px;
}

.af-form-row .af-form-group {
  flex: 1;
}

/* Button Styles */
.af-btn {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  transition: background-color 0.2s;
}

.af-btn-primary {
  background-color: #2196F3;
  color: white;
  border: none;
}

.af-btn-primary:hover {
  background-color: #0b7dda;
}

.af-btn-secondary {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
}

.af-btn-secondary:hover {
  background-color: #e0e0e0;
}

.af-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}