/* Base styles - Light mode by default */
:root {
    --ef-primary-color: #3b82f6;
    --ef-primary-hover: #2563eb;
    --ef-text-color: #1f2937;
    --ef-text-secondary: #6b7280;
    --ef-border-color: #e5e7eb;
    --ef-background-color: #ffffff;
    --ef-background-hover: #f3f4f6;
    --ef-modal-overlay: rgba(0, 0, 0, 0.5);
    --ef-shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    --ef-shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --ef-shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --ef-radius: 0.375rem;
    --ef-transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  /* Dark mode - Only applied when .ef-dark class is present */
  .ef-dark {
    --ef-text-color: #f3f4f6;
    --ef-text-secondary: #9ca3af;
    --ef-border-color: #374151;
    --ef-background-color: #111827;
    --ef-background-hover: #1f2937;
    --ef-modal-overlay: rgba(0, 0, 0, 0.7);
  }
  
  /* Editable field container */
  .ef-editable-field {
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: var(--ef-radius);
    transition: background-color var(--ef-transition);
    padding-left: 0px;
  }
  
  .ef-editable-field:hover {
    background-color: var(--ef-background-hover);
  }
  
  .ef-editable-field-value {
    font-size: 14px;
    font-weight: 500;
    color: var(--ef-text-color);
  }
  
  .ef-edit-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    transition: opacity var(--ef-transition);
  }
  
  .ef-editable-field:hover .ef-edit-button {
    opacity: 1;
  }
  
  .ef-edit-button:focus {
    opacity: 1;
    outline: none;
  }
  
  .ef-edit-icon {
    width: 16px;
    height: 16px;
    stroke: var(--ef-text-secondary);
    transition: stroke var(--ef-transition);
  }
  
  .ef-edit-button:hover .ef-edit-icon {
    stroke: var(--ef-primary-color);
  }
  
  /* Modal overlay */
  .ef-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--ef-modal-overlay);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
  }
  
  .ef-modal-backdrop {
    position: absolute;
    inset: 0;
  }
  
  .ef-modal {
    position: relative;
    width: 100%;
    max-width: 28rem;
    background-color: var(--ef-background-color);
    border-radius: var(--ef-radius);
    box-shadow: var(--ef-shadow-lg);
    padding: 1.5rem;
    z-index: 10;
    animation: ef-modal-appear 0.2s ease-out;
  }
  
  @keyframes ef-modal-appear {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .ef-modal-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--ef-text-color);
    margin-bottom: 1rem;
  }
  
  .ef-modal-content {
    margin-bottom: 1.5rem;
  }
  
  .ef-form-group {
    margin-bottom: 0.5rem;
  }
  
  .ef-form-label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--ef-text-secondary);
    margin-bottom: 0.5rem;
  }
  
  .ef-form-input,
  .ef-form-select {
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: var(--ef-text-color);
    background-color: var(--ef-background-color);
    border: 1px solid var(--ef-border-color);
    border-radius: var(--ef-radius);
    transition: border-color var(--ef-transition), box-shadow var(--ef-transition);
  }
  
  .ef-form-input:focus,
  .ef-form-select:focus {
    outline: none;
    border-color: var(--ef-primary-color);
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.25);
  }
  
  .ef-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
  }
  
  .ef-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: var(--ef-radius);
    transition: all var(--ef-transition);
    cursor: pointer;
  }
  
  .ef-btn-secondary {
    color: var(--ef-text-color);
    background-color: transparent;
    border: 1px solid var(--ef-border-color);
  }
  
  .ef-btn-secondary:hover {
    background-color: var(--ef-background-hover);
  }
  
  .ef-btn-primary {
    color: white;
    background-color: var(--ef-primary-color);
    border: 1px solid transparent;
  }
  
  .ef-btn-primary:hover {
    background-color: var(--ef-primary-hover);
  }
  
  /* Demo styles */
  .ef-demo-container {
    max-width: 32rem;
    margin: 2rem auto;
    padding: 1.5rem;
    border: 1px solid var(--ef-border-color);
    border-radius: var(--ef-radius);
    box-shadow: var(--ef-shadow-sm);
    background-color: var(--ef-background-color);
  }
  
  .ef-demo-title {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--ef-text-color);
    margin-bottom: 1.5rem;
  }
  
  .ef-demo-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 0;
    border-bottom: 1px solid var(--ef-border-color);
  }
  
  .ef-demo-item:last-child {
    border-bottom: none;
  }
  
  .ef-demo-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--ef-text-secondary);
  }
  
  /* Theme toggle */
  .ef-theme-toggle {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: 1px solid var(--ef-border-color);
    border-radius: var(--ef-radius);
    padding: 0.5rem;
    cursor: pointer;
    color: var(--ef-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color var(--ef-transition);
  }
  
  .ef-theme-toggle:hover {
    background-color: var(--ef-background-hover);
  }
  
  .ef-theme-toggle svg {
    width: 1rem;
    height: 1rem;
    stroke: currentColor;
  }
  
  