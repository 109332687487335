.monthly-goals {
    border-radius: 8px;
    margin: 0 auto;
    width: 100%;
  }
  
  .monthly-goals h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 16px;
    color: white;
  }
  
  .goals-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  
  .goal-chip {
    background-image:linear-gradient(135deg, #6ea0fb, #a777e3) ;
    border-radius: 20px;
    padding: 6px 12px;
    display: inline-flex;
    align-items: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    position: relative;
  }
  
  .goal-chip:hover {
    background-color: #d1d5db;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .goal-count {
    font-size: 1rem;
    font-weight: bold;
    color: white;
    margin-right: 6px;
  }
  
  .goal-action {
    font-size: 0.875rem;
    color: white;
  }
  
  .goal-tooltip {
    visibility: hidden;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #1f2937;
    color: #ffffff;
    text-align: center;
    border-radius: 4px;
    padding: 8px;
    width: 160px;
    font-size: 0.75rem;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    pointer-events: none;
  }
  
  .goal-chip:hover .goal-tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  @media (max-width: 640px) {
    .goals-grid {
      justify-content: center;
    }
  }
  
  