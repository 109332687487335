.user-data-card {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    color: white;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
  }
  
  .user-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    margin-right: 12px;
  }
  
  .user-name {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
  
  .data-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 12px;
  }
  
  .data-item {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 8px;
  }
  
  .label {
    font-size: 12px;
    opacity: 0.8;
    margin-bottom: 4px;
    display: block;
  }
  
  .value {
    font-size: 16px;
    font-weight: 600;
  }
  
  .progress-bar {
    height: 6px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    overflow: hidden;
    margin-top: 4px;
  }
  
  .progress {
    height: 100%;
    background-color: #4CAF50;
    border-radius: 3px;
    transition: width 0.5s ease-out;
  }
  
  @media (max-width: 600px) {
    .data-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  