.aud-wrapper {
    padding: 20px;
    border-radius: 12px;
    color: #1e293b;
  }
  
  .aud-title {
    font-size: 1.5rem;
    color: #3b82f6;
    margin-bottom: 1.5rem;
    text-align: start;
    font-weight: 600;
  }
  
  .aud-card-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
  }
  
  .aud-activity-card {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.2s ease;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  
  .aud-activity-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(59, 130, 246, 0.1);
  }
  
  .aud-activity-name {
    font-size: 0.9rem;
    color: #4b5563;
    margin-bottom: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    margin: 0px;
  }
  
  .aud-activity-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }
  
  .aud-activity-count {
    font-size: 1.5rem;
    font-weight: bold;
    color: #3b82f6;
  }
  
  .aud-activity-card-hot {
    background-color: #fff1f2;
  }
  
  .aud-activity-card-hot .aud-activity-count {
    color: #ef4444;
  }
  
  .aud-activity-fire {
    font-size: 1.2rem;
    animation: aud-pulse 1.5s infinite;
  }
  
  .aud-activity-progress {
    height: 3px;
    background-color: #3b82f6;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.5s ease-out;
  }
  
  .aud-activity-card-hot .aud-activity-progress {
    background-color: #ef4444;
  }
  
  @keyframes aud-pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  .header-monthly-achievment {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  @media (max-width: 640px) {
    .aud-wrapper {
      padding: 1rem;
    }
  
    .aud-title {
      font-size: 1.25rem;
    }
  
    .aud-card-layout {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }
  
  

  .user-full-data {
    display: grid;
    gap: 20px;
    padding-top: 20px;
  }