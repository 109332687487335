:root {
    --workflow-primary: #4c4cff;
    --workflow-primary-hover: #3939ff;
    --workflow-primary-light: rgba(76, 76, 255, 0.1);
    --workflow-text: #111827;
    --workflow-text-secondary: #6b7280;
    --workflow-background: #ffffff;
    --workflow-border: #e5e7eb;
    --workflow-radius: 0.75rem;
    --workflow-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    --workflow-shadow-hover: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .workflow-card {
    background: var(--workflow-background);
    border: 1px solid var(--workflow-border);
    border-radius: var(--workflow-radius);
    box-shadow: var(--workflow-shadow);
    width: 100%;
    max-width: 48rem;
    transition: box-shadow 0.2s, transform 0.2s;
  }
  
  .workflow-card:hover {
    box-shadow: var(--workflow-shadow-hover);
    transform: translateY(-1px);
  }
  
  .workflow-card-header {
    padding: 1.25rem 1.5rem 0.75rem;
  }
  
  .workflow-card-content {
    padding: 0 1.5rem 1.25rem;
  }
  
  .workflow-flex {
    display: flex;
  }
  
  .workflow-flex-between {
    justify-content: space-between;
  }
  
  .workflow-items-center {
    align-items: center;
  }
  
  .workflow-items-start {
    align-items: flex-start;
  }
  
  .workflow-gap-2 {
    gap: 0.5rem;
  }
  
  .workflow-gap-4 {
    gap: 1rem;
  }
  
  .workflow-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--workflow-text);
    margin: 0;
    line-height: 1.4;
  }
  
  .workflow-description {
    font-size: 0.875rem;
    color: var(--workflow-text-secondary);
    margin: 0.25rem 0 0 0;
  }
  
  .workflow-status-badge {
    background: var(--workflow-primary-light);
    color: var(--workflow-primary);
    padding: 0.375rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.025em;
  }
  
  .workflow-icon-button {
    background: transparent;
    border: none;
    padding: 0.5rem;
    border-radius: var(--workflow-radius);
    cursor: pointer;
    color: var(--workflow-text-secondary);
    transition: all 0.2s;
  }
  
  .workflow-icon-button:hover {
    color: var(--workflow-text);
    background: #f3f4f6;
    transform: scale(1.05);
  }
  
  .workflow-text-secondary {
    color: var(--workflow-text-secondary);
    font-size: 0.875rem;
  }
  
  .workflow-info-section {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem 0;
    border-top: 1px solid var(--workflow-border);
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    margin: 0px;
    padding-bottom: 0px;
  }
  
  .workflow-info-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    background: #f9fafb;
    border-radius: var(--workflow-radius);
    font-size: 0.875rem;
  }
  
  .workflow-progress-section {
    margin-top: 0;
  }
  
  .workflow-progress-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
  }
  
  .workflow-progress-info {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 0.875rem;
    color: var(--workflow-text-secondary);
  }
  
  .workflow-view-tasks-button {
    background: transparent;
    border: none;
    color: var(--workflow-primary);
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    border-radius: var(--workflow-radius);
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .workflow-view-tasks-button:hover {
    background: var(--workflow-primary-light);
    transform: translateY(-1px);
  }
  
  .workflow-progress {
    width: 100%;
    height: 8px;
    background: #e5e7eb;
    border-radius: 9999px;
    overflow: hidden;
  }
  
  .workflow-progress-bar {
    height: 100%;
    background: var(--workflow-primary);
    transition: width 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .workflow-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    z-index: 50;
  }
  
  .workflow-modal-overlay.open {
    opacity: 1;
    visibility: visible;
  }
  
  .workflow-modal {
    background: var(--workflow-background);
    border-radius: var(--workflow-radius);
    padding: 1.5rem;
    width: 90%;
    max-width: 32rem;
    transform: translateY(20px);
    transition: transform 0.3s;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  
  .workflow-modal-overlay.open .workflow-modal {
    transform: translateY(0);
  }
  
  .workflow-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--workflow-border);
  }
  
  .workflow-modal-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
    color: var(--workflow-text);
  }
  
  .workflow-task-list {
    max-height: 60vh;
    overflow-y: auto;
    padding-right: 0.5rem;
  }
  
  .workflow-task-list::-webkit-scrollbar {
    width: 6px;
  }
  
  .workflow-task-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  
  .workflow-task-list::-webkit-scrollbar-thumb {
    background: #d1d5db;
    border-radius: 3px;
  }
  
  .workflow-task-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    border-radius: var(--workflow-radius);
    transition: background-color 0.2s;
  }
  
  .workflow-task-item:hover {
    background: #f9fafb;
  }
  
  .workflow-task-checkbox {
    appearance: none;
    width: 1.125rem;
    height: 1.125rem;
    border: 2px solid var(--workflow-border);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s;
  }
  
  .workflow-task-checkbox:hover {
    border-color: var(--workflow-primary);
  }
  
  .workflow-task-checkbox:checked {
    background: var(--workflow-primary);
    border-color: var(--workflow-primary);
  }
  
  .workflow-task-checkbox:checked::after {
    content: "";
    position: absolute;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  .workflow-task-name {
    flex: 1;
    font-size: 0.875rem;
  }
  
  .workflow-task-status {
    font-size: 0.75rem;
    font-family: ui-monospace, monospace;
    padding: 0.25rem 0.5rem;
    border: 1px solid var(--workflow-border);
    border-radius: var(--workflow-radius);
    background: #f9fafb;
  }
  
  