
  .title {
    color: #1976d2;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .date-picker-stack {
    display: flex;
    justify-content: center;
  }
  
  .date-picker {
    width: 150px;
    height: 40px ;
    font-size: 12px !important;
  }
  
  .modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 24;
    padding: 20px;
    border-radius: 8px;
    max-width: 1200px;
    width: 90%;
  }
  
  .section-title {
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .data-grid-container {
    margin-top: 20px;
  }
  
  .table-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: scroll;
  }
  
  .table-header-row {
    background-color: #1976d2;
  }
  
  .table-header-cell {
    color: #fff;
    font-weight: bold;
  }
  
  .table-row {
    transition: background-color 0.3s ease;
  }
  
  .table-row:hover {
    background-color: #e3f2fd;
  }
  
  .table-cell {
    padding: 10px;
  }
  
  .summary-section {
    width: 300px;
    height: 300px;
  }