.aging-report {
    --ar-primary-color: #2563eb;
    --ar-primary-light: #3b82f6;
    --ar-danger-color: #dc2626;
    --ar-danger-light: #fee2e2;
    --ar-text-primary: #111827;
    --ar-text-secondary: #6b7280;
    --ar-bg-section: #f3f4f6;
    --ar-border-color: #e5e7eb;
  
    width: 100%;
    background: white;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border: 1px solid var(--ar-border-color);
  }
  
  .aging-report__gradient {
    height: 0.5rem;
    background: linear-gradient(to right, rgba(37, 99, 235, 0.2), rgba(37, 99, 235, 0.4), rgba(37, 99, 235, 0.6));
  }
  
  .aging-report__header {
    padding: 1.5rem 1.5rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .aging-report__company-name {
    font-size: 1rem;
    font-weight: 600;
    color: var(--ar-text-primary);
    margin: 0;
  }
  
  .aging-report__company-id {
    font-size: 0.875rem;
    color: var(--ar-text-secondary);
    margin: 0.25rem 0 0;
  }
  
  .aging-report__total {
    text-align: right;
  }
  
  .aging-report__total-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--ar-text-primary);
    margin: 0;
  }
  
  .aging-report__total-amount {
    font-size: 1.125rem;
    font-weight: 700;
    color: var(--ar-primary-color);
    margin: 0;
  }
  
  .aging-report__content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .aging-report__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
  }
  
  .aging-report__period {
    background: var(--ar-bg-section);
    padding: 0.75rem;
    border-radius: 0.5rem;
  }
  
  .aging-report__period--overdue {
    background: var(--ar-danger-light);
  }
  
  .aging-report__period-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  }
  
  .aging-report__period--overdue .aging-report__period-header {
    color: var(--ar-danger-color);
    font-weight: 500;
  }
  
  .aging-report__progress {
    height: 0.25rem;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0.125rem;
    overflow: hidden;
  }
  
  .aging-report__progress-fill {
    height: 100%;
    background: var(--ar-primary-light);
    transition: width 0.3s ease;
  }
  
  .aging-report__progress-fill--overdue {
    background: var(--ar-danger-color);
  }
  
  /* Optional: Add hover effects */
  .aging-report__period:hover {
    background: var(--ar-bg-section);
    opacity: 0.95;
  }
  
  .aging-report__period--overdue:hover {
    background: var(--ar-danger-light);
    opacity: 0.95;
  }
  
  /* Optional: Add responsive adjustments */
  @media (max-width: 480px) {
    .aging-report__row {
      grid-template-columns: 1fr;
    }
  
    .aging-report__company-name {
      font-size: 0.875rem;
    }
  
    .aging-report__total-amount {
      font-size: 1rem;
    }
  }
  
  