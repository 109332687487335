:root {
    --primary-color: #3b82f6;
    --secondary-color: #1e40af;
    --background-color: #f3f4f6;
    --text-color: #1f2937;
    --success-color: #10b981;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-20px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: var(--background-color);
    transition: background-color 0.5s ease;
  }
  
  .loader-container {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    width: 90%;
    max-width: 400px;
    animation: fadeIn 0.5s ease-out;
  }
  
  .loader-title {
    text-align: center;
    margin-bottom: 1.5rem;
    color: var(--text-color);
    font-size: 1.5rem;
    font-weight: 600;
    animation: slideIn 0.5s ease-out;
  }
  
  .progress-bar {
    height: 4px;
    background-color: #e5e7eb;
    border-radius: 2px;
    overflow: hidden;
    margin-bottom: 1.5rem;
  }
  
  .progress {
    height: 100%;
    background-color: var(--primary-color);
    transition: width 0.5s ease-out;
  }
  
  .step {
    margin-bottom: 1rem;
    animation: fadeIn 0.3s ease-out;
  }
  
  .step-content {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: #f9fafb;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .step-content:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .step-icon {
    width: 24px;
    height: 24px;
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--primary-color);
    transition: all 0.3s ease;
  }
  
  .step-icon.completed {
    background-color: var(--success-color);
    border-color: var(--success-color);
    color: #ffffff;
    animation: pulse 0.5s ease-out;
  }
  
  .step-text {
    flex-grow: 1;
  }
  
  .step-count {
    font-weight: 600;
    color: var(--secondary-color);
    margin-right: 0.5rem;
  }
  
  .completion-message {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    color: var(--success-color);
    margin-top: 1rem;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.5s ease;
  }
  
  .completion-message.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  @media (max-width: 480px) {
    .loader-container {
      padding: 1.5rem;
    }
  
    .loader-title {
      font-size: 1.25rem;
    }
  
    .step-content {
      font-size: 0.875rem;
    }
  }
  
  