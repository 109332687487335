.ytu-wrapper {
    font-family: 'Arial', sans-serif;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .ytu-main-heading {
    text-align: center;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .ytu-months-layout {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .ytu-month-item {
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .ytu-month-item:hover {
    transform: translateY(-5px);
  }
  
  .ytu-month-heading {
    color: #2c3e50;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .ytu-users-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .ytu-user-item {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: ytuFadeIn 0.5s ease forwards;
    opacity: 0;
  }
  
  .ytu-rank-badge {
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 1rem;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
  }
  
  .ytu-rank-1 .ytu-rank-badge {
    background-color: #ffd700;
  }
  
  .ytu-rank-2 .ytu-rank-badge {
    background-color: #c0c0c0;
  }
  
  .ytu-rank-3 .ytu-rank-badge {
    background-color: #cd7f32;
  }
  
  .ytu-user-info {
    flex-grow: 1;
  }
  
  .ytu-user-name {
    font-weight: bold;
  }
  
  .ytu-user-title {
    font-size: 0.8rem;
    color: #666;
  }
  
  .ytu-user-stats {
    text-align: right;
  }
  
  .ytu-action-count {
    font-size: 0.9rem;
    color: #666;
  }
  
  .ytu-user-level {
    font-size: 0.8rem;
    color: #3498db;
    font-weight: bold;
  }
  
  .ytu-no-data {
    text-align: center;
    color: #999;
    font-style: italic;
  }
  
  @keyframes ytuFadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .header-top-users {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .ytu-months-layout {
      grid-template-columns: 1fr;
    }
  }
  
  .pro-select {
    font-family: 'Arial', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #f0f4f8;
  }
  
  .pro-select__wrapper {
    position: relative;
    width: 300px;
  }
  
  .pro-select__field {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    font-size: 16px;
    padding: 12px 20px;
    background-color: #ffffff;
    border: 1px solid #e1e5e9;
    border-radius: 6px;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .pro-select__field:hover, 
  .pro-select__field:focus {
    border-color: #3498db;
    box-shadow: 0 0 0 4px rgba(52, 152, 219, 0.1);
    outline: none;
  }
  
  .pro-select__wrapper::after {
    content: '\25BC';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 13px;
    color: #666;
    transition: transform 0.3s ease;
  }
  
  .pro-select__wrapper:hover::after,
  .pro-select__field:focus + .pro-select__wrapper::after {
    transform: translateY(-50%) rotate(180deg);
    color: #3498db;
  }
  
  .pro-select__option {
    padding: 12px;
    background-color: #ffffff;
    color: #333;
  }
  
  .pro-select__option:hover {
    background-color: #f0f4f8;
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .pro-select__wrapper {
      width: 100%;
      max-width: 300px;
    }
  }
  
  