.rf-fields-container {
    display: grid;
    gap: 0.5rem;
    width: 100%;
  }
  
  .rf-field-row {
    display: grid;
    grid-template-columns: minmax(100px, 30%) 1fr;
    gap: 0.5rem;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: hsl(var(--card));
    border-left: 3px solid transparent;
    transition: all 0.15s ease;
    position: relative;
  }
  
  .rf-field-row:hover {
    border-left-color: rgb(39, 116, 174);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    background-color: white;
  }
  
  .rf-field-row:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: -0.25rem;
    left: 0.5rem;
    right: 0.5rem;
    height: 1px;
    background-color: hsl(var(--border));
    opacity: 0.5;
  }
  
  .rf-field-label {
    display: flex;
    align-items: center;
    gap: 0.375rem;
  }
  
  .rf-field-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .rf-icon {
    color: hsl(var(--muted-foreground));
    width: 1rem;
    height: 1rem;
  }
  
  .rf-field-title {
    font-size: 0.75rem;
    color: hsl(var(--muted-foreground));
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .rf-field-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 1.5rem;
  }
  
  .rf-static-value {
    font-weight: 600;
    font-size: 0.8125rem;
  }
  
  .rf-array-values {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }
  
  .rf-array-item {
    font-weight: 500;
    font-size: 0.75rem;
    background-color: hsl(var(--accent));
    color: hsl(var(--accent-foreground));
    padding: 0.125rem 0.375rem;
    border-radius: 0.125rem;
  }
  
  .rf-editable-field {
    width: 100%;
  }
  
  .rf-view-mode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .rf-field-display {
    font-weight: 500;
    font-size: 0.8125rem;
  }
  
  .rf-edit-button {
    opacity: 0;
    background: none;
    border: none;
    cursor: pointer;
    color: hsl(var(--muted-foreground));
    padding: 0.125rem;
    border-radius: 0.125rem;
    transition: all 0.15s ease;
  }
  
  .rf-edit-button:hover {
    color: hsl(var(--primary));
    background-color: hsl(var(--primary) / 0.1);
  }
  
  .rf-field-row:hover .rf-edit-button {
    opacity: 1;
  }
  
  .rf-edit-mode {
    display: flex;
    gap: 0.25rem;
    width: 100%;
  }
  
  .rf-edit-input {
    flex: 1;
    padding: 0.25rem 0.375rem;
    border-radius: 0.25rem;
    border: 1px solid hsl(var(--border));
    background-color: hsl(var(--background));
    font-size: 0.8125rem;
    height: 1.75rem;
  }
  
  .rf-edit-input:focus {
    outline: none;
    border-color: hsl(var(--primary));
    box-shadow: 0 0 0 1px hsl(var(--primary) / 0.3);
  }
  
  .rf-edit-actions {
    display: flex;
    gap: 0.125rem;
  }
  
  .rf-action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.125rem;
    border-radius: 0.125rem;
    height: 1.75rem;
    width: 1.75rem;
    transition: all 0.15s ease;
  }
  
  .rf-save {
    color: hsl(var(--success, 142 76% 36%));
  }
  
  .rf-save:hover {
    background-color: hsl(var(--success, 142 76% 36%) / 0.1);
  }
  
  .rf-cancel {
    color: hsl(var(--destructive));
  }
  
  .rf-cancel:hover {
    background-color: hsl(var(--destructive) / 0.1);
  }
  
  