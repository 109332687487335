.trello-card {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #fff;
    border: 1px solid #e0e0e0;
  }

  .custom-fields {
    display: grid;
    grid-template-columns: repeat(auto-fit , minmax(250px , 1fr));
    gap: 10px;
  }
  
  .trello-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
  
  .trello-card-content {
    padding: 16px;
  }
  
  .trello-card-header {
    display: flex;
    align-items: center;
  }
  
  .trello-image-wrapper {
    flex-shrink: 0;
    margin-right: 12px;
  }
  
  .trello-image {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    object-fit: cover;
    display: block;
  }

  .trello-image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 0, 0, 0.5); /* Red overlay */
    mix-blend-mode: multiply;
  }
  
  .trello-card-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333;
  }
  
  .trello-card-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.4;
    margin: 5px;
  }
  
  .trello-chip-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .trello-chip {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }
  
  .trello-chip-icon {
    margin-right: 4px;
    font-size: 14px;
  }
  
  .trello-status-bar {
    height: 4px;
    width: 100%;
  }
  
  .trello-status-success {
    background-color: #4caf4f4a;
  }
  
  .trello-status-warning {
    background-color: #ff99004b;
  }
  
  .trello-status-danger {
    background-color: #f443364d;
  }
  
  .trello-chip-success {
    background-color: #e8f5e9;
    color: #2e7d32;
  }
  
  .trello-chip-warning {
    background-color: #fff3e0;
    color: #ef6c00;
  }
  
  .trello-chip-danger {
    background-color: #ffebee;
    color: #c62828;
  }
  
  .trello-chip-default {
    background-color: #f5f5f5;
    color: #616161;
  }
  
  .trello-chip-custom {
    background-color: #e3f2fd;
    color: #1565c0;
  }
  
  .trello-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .trello-modal {
    background-color: #fff;
    border-radius: 8px;
    width: 90%;
    max-width: 480px;
    max-height: 90vh;
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
  
  .trello-close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    color: #666;
    transition: color 0.3s ease;
    font-size: 18px;
  }
  
  .trello-close-button:hover {
    color: #333;
  }
  
  .trello-modal-header {
    display: flex;
    align-items: center;
    padding: 24px 24px 16px;
  }
  
  .trello-modal-title {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    color: #333;
  }
  
  .trello-modal-description {
    padding: 0 24px 16px;
    font-size: 14px;
    color: #666;
    line-height: 1.6;
  }
  
  .trello-custom-fields-container {
    padding: 0 24px 16px;
  }
  
  .trello-section-title {
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 12px;
    color: #333;
  }
  
  .trello-date-container {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    background-color: #f5f5f5;
    border-top: 1px solid #e0e0e0;
  }
  
  @media (max-width: 480px) {
    .trello-card {
      max-width: 100%;
    }
  
    .trello-modal {
      width: 95%;
    }
  }
  
  


.labels-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0px 24px 0px;
}

  .label-chip {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
    border-width: 1px;
    border-style: solid;
  }
  
  /* Color variants */
  .label-chip.red_dark {
    background-color: #fee2e2;
    color: #991b1b;
    border-color: #fecaca;
  }
  
  .label-chip.blue_dark {
    background-color: #dbeafe;
    color: #1e40af;
    border-color: #bfdbfe;
  }
  
  .label-chip.green_dark {
    background-color: #dcfce7;
    color: #166534;
    border-color: #bbf7d0;
  }
  
  .label-chip.yellow_dark {
    background-color: #fef3c7;
    color: #92400e;
    border-color: #fde68a;
  }
  
  .label-chip.purple_dark {
    background-color: #f3e8ff;
    color: #6b21a8;
    border-color: #e9d5ff;
  }
  
  .label-chip.gray_dark {
    background-color: #f3f4f6;
    color: #1f2937;
    border-color: #e5e7eb;
  }
  
  