.search-filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px  ;
    border-radius: 6px;

  }

  .left-group {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
  }

  .search-bar {
    flex-grow: 1;
    max-width: 400px;
    padding: 10px 15px;
    border: 1px solid #ced4da;
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.3s;
    height: 39px;
  }

  .search-bar:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  .filter-button,
  .create-bulk-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.1s;
  }

  .filter-button:hover,
  .create-bulk-button:hover {
    background-color: #0056b3;
  }

  .filter-button:active,
  .create-bulk-button:active {
    transform: translateY(1px);
  }

  .create-bulk-button {
    margin-left: 20px;
    background-color: #28a745;
  }

  .create-bulk-button:hover {
    background-color: #218838;
  }

  .filter-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .filter-popup-content {
    background-color: white;
    padding: 25px;
    border-radius: 6px;
    width: 600px;
    max-width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .close-button {
    float: right;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #6c757d;
    transition: color 0.3s;
  }

  .close-button:hover {
    color: #343a40;
  }

  .filter-group {
    margin-bottom: 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 10px;
  }

  .filter-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #495057;
  }

  .filter-group input,
  .filter-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.3s;
  }

  .filter-group input:focus,
  .filter-group select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  @media (max-width: 768px) {
    .search-filter-container {
      flex-direction: column;
      align-items: stretch;
    }

    .left-group {
      margin-bottom: 15px;
    }

    .create-bulk-button {
      margin-left: 0;
      width: 100%;
    }

    .search-bar {
      max-width: none;
    }
  }