* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .folder-tree-container {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #333;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 400px;
  }

  .delete-icon {
    margin-left: 10px;
    cursor: pointer;
  }
  
  .confirmation-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .confirmation-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .confirmation-content button {
    margin: 10px;
    padding: 5px 10px;
    cursor: pointer;
  }
  .rename-icon {
    margin-left: 10px;
    cursor: pointer;
  }
  
  .confirmation-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .confirmation-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .confirmation-content input {
    margin: 10px 0;
    padding: 5px;
    width: 100%;
  }
  
  .confirmation-content button {
    margin: 10px;
    padding: 5px 10px;
    cursor: pointer;
  }
  /* Loading indicator */
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to { transform: rotate(360deg); }
  }

  /* Folder tree styles */
  .folder-tree-wrapper {
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .folder-tree-title {
    padding: 16px;
    margin: 0;
    background-color: #f1f3f5;
    border-bottom: 1px solid #e9ecef;
    font-size: 18px;
    font-weight: 600;
    color: #495057;
  }

  .folder-tree-content {
    padding: 8px;
    overflow-y: auto;
    flex-grow: 1;
  }

  .folder-tree-list {
    list-style-type: none;
    padding-left: 0;
  }

  .folder-tree-item {
    margin: 2px 0;
  }

  .folder-tree-list .folder-tree-list {
    padding-left: 20px;
  }

  /* Folder styles */
  .folder-item {
    margin-bottom: 4px;
  }

  .folder-label {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    user-select: none;
  }

  .folder-label:hover {
    background-color: #e9ecef;
  }

  .folder-label.expanded {
    background-color: #e9ecef;
  }

  .folder-icon {
    margin-right: 8px;
    font-size: 16px;
  }

  .folder-name {
    flex-grow: 1;
    font-size: 14px;
  }

  .folder-arrow {
    font-size: 10px;
    color: #868e96;
    margin-left: 8px;
  }

  .folder-children {
    display: none;
    margin-top: 2px;
  }

  .folder-children.visible {
    display: block;
  }

  /* File styles */
  .file-item {
    margin-bottom: 2px;
  }

  .file-label {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .file-label:hover {
    background-color: #e9ecef;
  }

  .file-icon {
    margin-right: 8px;
    font-size: 16px;
  }

  .file-name {
    font-size: 14px;
    color: #495057;
  }

  /* Popup styles */
  .file-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.2s ease-out;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  .file-popup-content {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: 90%;
    max-width: 900px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    animation: slideUp 0.3s ease-out;
  }

  @keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }

  .file-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid #e9ecef;
  }

  .file-popup-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: #212529;
  }

  .file-popup-close {
    background: none;
    border: none;
    font-size: 24px;
    color: #868e96;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s;
  }

  .file-popup-close:hover {
    background-color: #f1f3f5;
    color: #495057;
  }

  .file-popup-body {
    padding: 20px;
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .file-preview-image {
    max-width: 100%;
    max-height: 70vh;
    object-fit: contain;
    border-radius: 4px;
  }

  .file-preview-iframe {
    width: 100%;
    height: 70vh;
    border: 1px solid #e9ecef;
    border-radius: 4px;
  }

  .file-preview-fallback {
    text-align: center;
    padding: 40px 20px;
  }

  .file-preview-fallback p {
    margin-bottom: 20px;
    color: #868e96;
  }

  .download-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4dabf7;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    transition: background-color 0.2s;
  }

  .download-button:hover {
    background-color: #339af0;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .file-popup-content {
      width: 95%;
      max-width: none;
      height: 95vh;
      max-height: none;
    }
    
    .file-preview-iframe {
      height: 60vh;
    }
  }