:root {
    --hv-color-primary: #06358c;
    --hv-color-primary-light: #d1e0fa;
    --hv-color-text: #27272a;
    --hv-color-text-light: #71717a;
    --hv-color-background: #ffffff;
    --hv-color-border: #e4e4e7;
    --hv-color-hover: #f4f4f5;
    --hv-shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
    --hv-shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --hv-radius: 12px;
    --hv-radius-sm: 8px;
    --hv-transition: 300ms ease;
  }
  
  .hv-card-wrapper {
    text-decoration: none;
    color: inherit;
    display: block;
  }
  
  .hv-card-container {
    position: relative;
    max-width: 400px;
    padding: 1.5rem;
    background: white;
    border: 1px solid var(--hv-color-border);
    border-radius: var(--hv-radius);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    transition: transform var(--hv-transition), box-shadow var(--hv-transition);
  }
  
  .hv-card-container:hover {
    transform: translateY(-4px);
    box-shadow: var(--hv-shadow-md);
  }
  
  .hv-status-indicator {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    gap: 0.375rem;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
  }
  
  .hv-status-active {
    background-color: var(--hv-color-primary-light);
    color: var(--hv-color-primary);
  }
  
  .hv-status-inactive {
    background-color: var(--hv-color-hover);
    color: var(--hv-color-text-light);
  }
  
  .hv-status-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  
  .hv-project-identifier {
    font-size: 0.75rem;
    color: var(--hv-color-text-light);
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .hv-card-header {
    margin-bottom: 1.5rem;
    padding-right: 6rem;
  }
  
  .hv-project-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--hv-color-text);
    margin: 0 0 0.75rem 0;
    transition: color var(--hv-transition);
  }
  
  .hv-card-wrapper:hover .hv-project-title {
    color: var(--hv-color-primary);
  }
  
  .hv-date-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--hv-color-text-light);
    font-size: 0.875rem;
  }
  
  .hv-icon {
    width: 1rem;
    height: 1rem;
    color: var(--hv-color-primary);
  }
  
  .hv-stats-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .hv-stat-box {
    background-color: var(--hv-color-hover);
    border: 1px solid var(--hv-color-border);
    border-radius: var(--hv-radius-sm);
    padding: 0.75rem;
  }
  
  .hv-stat-box-header {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    color: var(--hv-color-text-light);
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }
  
  .hv-stat-box-value {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--hv-color-text);
  }
  
  .hv-financial-section {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }
  
  .hv-financial-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--hv-color-border);
  }
  
  .hv-financial-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--hv-color-text-light);
    font-size: 0.875rem;
  }
  
  .hv-financial-value {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--hv-color-text);
  }
  
  .hv-project-footer {
    margin-top: 1rem;
    font-size: 0.75rem;
    color: var(--hv-color-text-light);
  }
  
  @media (max-width: 640px) {
    .hv-card-container {
      max-width: 100%;
    }
  
    .hv-card-header {
      padding-right: 0;
    }
  
    .hv-status-indicator {
      position: static;
      display: inline-flex;
      margin-bottom: 1rem;
    }
  }
  
  