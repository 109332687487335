.process-container {
    margin: 0 auto;
    padding: 1rem;
    width: 100%;
  }
  
  .process-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .process-status-overview {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .process-status-overview {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .process-status-overview {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .process-status-card {
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  
  .process-status-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .process-status-card-title {
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0px;
  }
  
  .process-status-card-count {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .process-accordion {
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  
  .process-accordion-item:not(:last-child) {
    border-bottom: 1px solid #e2e8f0;
  }
  
  .process-accordion-trigger {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8fafc;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border: none;
  }
  
  .process-accordion-trigger:hover {
    background-color: #f1f5f9;
  }
  
  .process-accordion-trigger-content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .process-accordion-icon {
    transition: transform 0.2s ease;
  }
  
  .process-accordion-trigger[aria-expanded="true"] .process-accordion-icon {
    transform: rotate(180deg);
  }
  
  .process-accordion-content {
    padding: 1rem;
    background-color: #ffffff;
  }
  
  .process-card {
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  
  .process-card-header {
    margin-bottom: 0.5rem;
  }
  
  .process-card-title {
    font-size: 1.125rem;
    font-weight: bold;
  }
  
  .process-card-description {
    font-size: 0.875rem;
    color: #64748b;
  }
  
  .process-card-content {
    display: grid;
    gap: 0.5rem;
  }
  
  .process-card-section {
    margin-top: 0.5rem;
  }
  
  .process-card-section-title {
    font-weight: bold;
  }
  
  .process-card-section-content {
    padding-left: 1rem;
  }
  
  .process-failed-reason {
    color: #ef4444;
  }
  
  .process-badge {
    background-color: #e2e8f0;
    color: #1e293b;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
  }
  
  .process-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .process-icon-yellow {
    color: #eab308;
  }
  
  .process-icon-blue {
    color: #3b82f6;
  }
  
  .process-icon-green {
    color: #22c55e;
  }
  
  .process-icon-red {
    color: #ef4444;
  }
  
  