.ml-members-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  margin: 5px;
}

.ml-member-container {
  position: relative;
}

/* Styling for inline mode */
.ml-inline-mode {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.ml-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background: linear-gradient(135deg, #6366f1, #4f46e5);
}

.ml-avatar:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Inline name styling */
.ml-inline-name {
  margin-left: 8px;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  animation: mlSlideIn 0.2s ease-in-out;
}

.ml-tooltip {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 16px;
  min-width: 240px;
  z-index: 999000;
  z-index: 9999 !important;
  animation: mlFadeIn 0.2s ease-in-out;
}

.ml-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  z-index: 9999 !important;
}

.ml-tooltip-content {
  display: flex;
  gap: 12px;
}

.ml-tooltip-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: white;
  background: linear-gradient(135deg, #6366f1, #4f46e5);
}

.ml-tooltip-info {
  flex: 1;
}

.ml-tooltip-info h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #1a1a1a;
}

.ml-tooltip-info .ml-username {
  margin: 4px 0 8px;
  color: #666;
  font-size: 0.85rem;
}

.ml-tooltip-info .ml-dates {
  font-size: 0.75rem;
  color: #666;
}

.ml-tooltip-info .ml-dates p {
  margin: 2px 0;
}

@keyframes mlFadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes mlSlideIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

