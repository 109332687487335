@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.actions-container {
  font-family: 'Inter', sans-serif;
  max-width: 600px;
  margin: 0 auto;
  padding: 5px;
  border-radius: 16px;
}

.actions-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.action-item {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px 20px;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px #0000001a;
  cursor: pointer;
}

.action-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-color: #cbd5e1;
}

.action-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-title {
  color: #1e293b;
  font-size: 0.95rem;
  font-weight: 500;
  margin: 0;
  flex: 1;
}

.action-count {
  font-size: 1.1rem;
  font-weight: 600;
  color: #0284c7;
  background-color: #e0f2fe;
  padding: 4px 12px;
  border-radius: 20px;
  min-width: 32px;
  text-align: center;
}

@media (max-width: 480px) {
  .actions-container {
  }

  .action-item {
    padding: 14px 16px;
  }

  .action-title {
    font-size: 0.9rem;
  }

  .action-count {
    font-size: 1rem;
    padding: 3px 10px;
  }
}

