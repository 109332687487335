.time-tracker-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
}

.time-tracker-empty {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #7f8c8d;
}

.time-tracker-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.time-tracker-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  cursor: pointer;
}

.project-avatar {
  width: 48px;
  height: 48px;
  margin-right: 1rem;
}

.project-info {
  flex-grow: 1;
}

.project-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
}

.client-name {
  font-size: 0.9rem;
  color: #7f8c8d;
  margin: 0.25rem 0 0;
}

.card-content {
  padding: 10px;
}

.task-controls {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  padding: 0px;
}

.timer-button {
  margin-left: 0.5rem;
}

.timer-button.start {
  color: #27ae60;
}

.timer-button.stop {
  color: #e74c3c;
}

.time-entries-compact {
  border-radius: 4px;
  padding: 0.5rem;
}

.card-actions {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-top: 1px solid #ecf0f1;
}

.open-project,
.expand-toggle {
  color: #3498db;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  width: 600px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-close {
  color: #7f8c8d;
}

@media (max-width: 768px) {
  .time-tracker-grid {
    grid-template-columns: 1fr;
  }
}

