:root {
    --primary-color: #1a73e8;
    --secondary-color: #4285f4;
    --background-color: #f8f9fa;
    --card-background: #ffffff;
    --text-primary: #202124;
    --text-secondary: #5f6368;
    --border-color: #dadce0;
  }
  
  body {
    font-family: 'Roboto', Arial, sans-serif;
    background-color: var(--background-color);
    color: var(--text-primary);
    line-height: 1.4;
  }
  
  .container {
    margin: 0 auto;
    padding: 20px 16px;
  }
  
  .title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    color: var(--primary-color);
  }
  
  .summary-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
    margin-bottom: 20px;
  }
  
  .summary-card {
    background-color: var(--card-background);
    border-radius: 8px;
    padding: 16px;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    align-items: center;
  }
  
  .summary-card-icon {
    color: var(--primary-color);
    margin-right: 12px;
  }
  
  .summary-card-content {
    display: flex;
    flex-direction: column;
  }
  
  .summary-card-title {
    font-size: 14px;
    color: var(--text-secondary);
    font-weight: 500;
    margin: 0;
  }
  
  .summary-card-value {
    font-size: 20px;
    font-weight: 500;
    color: var(--primary-color);
  }
  
  .charts {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 16px;
  }
  
  .card {
    background-color: var(--card-background);
    border-radius: 8px;
    padding: 16px;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 16px;
  }
  
  .card-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
    color: var(--primary-color);
  }
  
  .chart-placeholder {
    height: 300px;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: var(--text-secondary);
    font-size: 14px;
  }
  
  .action-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 12px;
  }
  
  .action-card {
    background-color: var(--background-color);
    border-radius: 8px;
    padding: 12px;
    text-align: center;
  }
  
  .action-name {
    font-size: 12px;
    color: var(--text-secondary);
    margin-bottom: 4px;
  }
  
  .action-count {
    font-size: 18px;
    font-weight: 500;
    color: var(--primary-color);
  }
  
  .top-users {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .top-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;
    border-bottom: 1px solid var(--border-color);
  }
  
  .points {
    font-weight: 500;
    color: var(--primary-color);
  }
  
  .table-container {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .table th,
  .table td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid var(--border-color);
  }
  
  .table th {
    background-color: var(--background-color);
    font-weight: 500;
    color: var(--text-secondary);
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.5px;
  }
  
  .table tr:last-child td {
    border-bottom: none;
  }
  
  .action-button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover {
    background-color: var(--background-color);
    color: var(--primary-color);
  }
  
  @media (max-width: 768px) {
    .summary-cards,
    .charts,
    .action-cards {
      grid-template-columns: 1fr;
    }
  
    .title {
      font-size: 20px;
    }
  
    .card-title {
      font-size: 16px;
    }
  
    .summary-card-value {
      font-size: 18px;
    }
  }
  
  
.header-analytics-range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.s-b0 {
   border-bottom: 3px solid rgb(255, 206, 86);
}

.s-b1 {
   border-bottom: 3px solid rgb(255, 99, 132);
}

.s-b2 {
    border-bottom: 3px solid rgb(54, 162, 235);
}

.actions-users {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}