/* Reset and base styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
    line-height: 1.5;
    color: #333;
    background-color: #f5f5f5;
  }
  
  /* Container */
  .fb-container {
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  /* Typography */
  .fb-page-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .fb-page-description {
    color: #666;
    margin-bottom: 2rem;
  }
  
  /* Form Builder Layout */
  .fb-form-builder-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  @media (min-width: 992px) {
    .fb-form-builder-grid {
      grid-template-columns: 2fr 1fr;
    }
    .fb-form-builder-grid2 {
        grid-template-columns:  1fr;
      }
  }
  
  .fb-form-builder-main {
    order: 2;
  }
  
  .fb-form-builder-sidebar {
    order: 1;
  }
  
  @media (min-width: 992px) {
    .fb-form-builder-main {
      order: 1;
    }
  
    .fb-form-builder-sidebar {
      order: 2;
    }
  }
  
  /* Form Header */
  .fb-form-header {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .fb-form-header-content {
    flex: 1;
  }
  
  .fb-form-title-input {
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    border: 0;
    border-bottom: 2px solid transparent;
    padding: 0.25rem 0;
    transition: border-color 0.2s;
  }
  
  .fb-form-title-input:focus {
    outline: none;
    border-bottom-color: #ddd;
  }
  
  .fb-form-description-input {
    width: 100%;
    color: #666;
    border: 0;
    border-bottom: 2px solid transparent;
    padding: 0.25rem 0;
    resize: none;
    transition: border-color 0.2s;
  }
  
  .fb-form-description-input:focus {
    outline: none;
    border-bottom-color: #ddd;
  }
  
  .fb-form-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .fb-form-description {
    color: #666;
  }
  
  .fb-preview-toggle-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #f3f4f6;
    color: #4b5563;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-left: 1rem;
  }
  
  .fb-preview-toggle-button:hover {
    background-color: #e5e7eb;
  }
  
  /* Form Preview */
  .fb-form-preview {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
  }
  
  .fb-form-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .fb-empty-form {
    background-color: white;
    border: 2px dashed #ddd;
    border-radius: 0.5rem;
    padding: 2rem;
    text-align: center;
  }
  
  .fb-empty-form-message {
    color: #9ca3af;
    margin-bottom: 1rem;
  }
  
  .fb-form-element {
    position: relative;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    transition: all 0.2s;
  }
  
  .fb-form-element:hover {
    background-color: #f9fafb;
  }
  
  .fb-form-element-selected {
    background-color: #ebf5ff;
    border-color: #bfdbfe;
  }
  
  .fb-element-controls {
    position: absolute;
    top: 50%;
    right: -0.75rem;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .fb-element-control-button {
    background-color: white;
    border: none;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .fb-element-control-button:hover {
    background-color: #f3f4f6;
  }
  
  .fb-element-delete-button:hover {
    background-color: #fee2e2;
    color: #ef4444;
  }
  
  .fb-form-submit-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
  
  .fb-form-submit-button {
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 0.375rem;
    padding: 0.5rem 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .fb-form-submit-button:hover {
    background-color: #1d4ed8;
  }
  
  /* Export Button */
  .fb-export-button-container {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }
  
  .fb-export-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 0.5rem;
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .fb-export-button:hover {
    background-color: #1d4ed8;
  }
  
  /* Sidebar Panels */
  .fb-sidebar-panel {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .fb-sidebar-panel-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  /* Element Selector */
  .fb-element-selector-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
  }
  
  .fb-element-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .fb-element-button:hover {
    background-color: #f9fafb;
    border-color: #d1d5db;
  }
  
  .fb-element-icon {
    color: #2563eb;
    margin-bottom: 0.5rem;
  }
  
  .fb-element-label {
    font-size: 0.875rem;
  }
  
  /* Form Settings */
  .fb-settings-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .fb-settings-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .fb-settings-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #4b5563;
  }
  
  .fb-settings-input {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    transition: all 0.2s;
  }
  
  .fb-settings-input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
  }
  
  .fb-options-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0.75rem;
  }
  
  .fb-option-item {
    display: flex;
    align-items: center;
  }
  
  .fb-option-input {
    flex: 1;
    padding: 0.25rem 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    transition: all 0.2s;
  }
  
  .fb-option-input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
  }
  
  .fb-option-remove-button {
    background: none;
    border: none;
    color: #9ca3af;
    cursor: pointer;
    margin-left: 0.5rem;
    transition: color 0.2s;
  }
  
  .fb-option-remove-button:hover {
    color: #ef4444;
  }
  
  .fb-add-option-container {
    display: flex;
  }
  
  .fb-add-option-input {
    flex: 1;
    padding: 0.5rem 0.75rem;
    border: 1px solid #d1d5db;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    transition: all 0.2s;
  }
  
  .fb-add-option-input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
  }
  
  .fb-add-option-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.75rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .fb-add-option-button:hover {
    background-color: #1d4ed8;
  }
  
  .fb-required-field-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .fb-required-field-label {
    font-size: 0.875rem;
    color: #4b5563;
  }
  
  .fb-required-mark {
    color: #ef4444;
    margin-left: 0.25rem;
  }
  
  /* Form Elements Styling */
  .fb-form-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .fb-form-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #4b5563;
  }
  
  .fb-form-input,
  .fb-form-textarea,
  .fb-form-select {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    transition: all 0.2s;
  }
  
  .fb-form-input:focus,
  .fb-form-textarea:focus,
  .fb-form-select:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
  }
  
  .fb-form-textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  .fb-form-select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
  }
  
  .fb-checkbox-group,
  .fb-radio-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  /* Add styles for checkbox groups */
  .fb-checkbox-group-container {
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 0.5rem;
  }
  
  .fb-checkbox-group-title {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .fb-checkbox-select-all {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .fb-checkbox-select-all label {
    margin-left: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .fb-checkbox-option,
  .fb-radio-option {
    display: flex;
    align-items: center;
  }
  
  .fb-form-checkbox,
  .fb-form-radio {
    appearance: none;
    width: 1rem;
    height: 1rem;
    border: 1px solid #d1d5db;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .fb-form-checkbox {
    border-radius: 0.25rem;
  }
  
  .fb-form-radio {
    border-radius: 50%;
  }
  
  .fb-form-checkbox:checked {
    background-color: #2563eb;
    border-color: #2563eb;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  
  .fb-form-radio:checked {
    background-color: #2563eb;
    border-color: #2563eb;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  
  .fb-form-checkbox:focus,
  .fb-form-radio:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
  }
  
  .fb-checkbox-label,
  .fb-radio-label {
    margin-left: 0.5rem;
    font-size: 0.875rem;
    color: #4b5563;
  }
  
  .fb-form-heading {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .fb-form-paragraph {
    color: #4b5563;
  }
  
  /* Modal */
  .fb-modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
  }
  
  .fb-modal-content {
    background-color: white;
    border-radius: 0.5rem;
    padding: 1.5rem;
    max-width: 28rem;
    width: 100%;
    display: grid;
    gap: 10px;
  }
  
  .fb-modal-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .fb-modal-description {
    margin-bottom: 1rem;
  }
  
  .fb-modal-actions {
    display: flex;
    justify-content: center;
    gap: 0.75rem;
  }
  
  .fb-modal-cancel-button {
    padding: 0.5rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .fb-modal-cancel-button:hover {
    background-color: #f3f4f6;
  }
  
  .fb-modal-confirm-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .fb-modal-confirm-button:hover {
    background-color: #1d4ed8;
  }
  
  /* Add styles for the share button */
  .fb-share-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #10b981;
    color: white;
    border: none;
    border-radius: 0.5rem;
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-left: 1rem;
  }
  
  .fb-share-button:hover {
    background-color: #059669;
  }
  
  /* Responsive adjustments */
  @media (max-width: 640px) {
    .fb-form-header {
      flex-direction: column;
    }
  
    .fb-preview-toggle-button {
      margin-left: 0;
      margin-top: 1rem;
      align-self: flex-start;
    }
  
    .fb-element-selector-grid {
      grid-template-columns: 1fr;
    }
  }
  
  
  .fb-field-checkboxes {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
  }

  .fb-collection-fields {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 15px;
  }

  /* Customer Creation Button Container */
.af-create-customer-container {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  text-align: center;
}

.af-create-customer-btn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.af-create-customer-btn:hover {
  background-color: #3e8e41;
}