/* Base styles */
.lf-client-records-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  color: #2d3748;
  max-width: 100%;
  padding: 1.5rem;
  background-color: #f7fafc;
  border-radius: 8px;
}

/* Header styles */
.lf-records-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e2e8f0;
}

.lf-records-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
}

.lf-records-controls {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

/* Search styles */
.lf-search-container {
  position: relative;
}

.lf-search-input {
  padding: 0.5rem 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 0.875rem;
  width: 220px;
  transition: all 0.2s ease;
}

.lf-search-input:focus {
  outline: none;
  border-color: #4a5568;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.lf-clear-search {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #a0aec0;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.lf-clear-search:hover {
  color: #4a5568;
}

/* View controls */
.lf-view-controls {
  display: flex;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  overflow: hidden;
}

.lf-view-button {
  background: white;
  border: none;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  cursor: pointer;
  color: #718096;
  transition: all 0.2s ease;
}

.lf-view-button:hover {
  background-color: #f7fafc;
}

.lf-view-button.lf-active {
  background-color: #edf2f7;
  color: #2d3748;
  font-weight: 500;
}

/* Refresh button */
.lf-refresh-button {
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  color: #718096;
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.lf-refresh-button:hover {
  background-color: #edf2f7;
  color: #2d3748;
}

/* Status containers (loading, error, empty) */
.lf-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  text-align: center;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  margin: 1rem 0;
}

.lf-loading-state {
  color: #4a5568;
}

.lf-error-state {
  color: #e53e3e;
  border-left: 4px solid #e53e3e;
}

.lf-empty-state {
  color: #718096;
  background-color: #f7fafc;
  border: 1px dashed #cbd5e1;
}

/* Loading spinner */
.lf-spinner {
  width: 36px;
  height: 36px;
  border: 3px solid rgba(203, 213, 224, 0.3);
  border-top-color: #4299e1;
  border-radius: 50%;
  animation: lf-spin 0.8s linear infinite;
  margin-bottom: 1rem;
}

@keyframes lf-spin {
  to {
    transform: rotate(360deg);
  }
}

.lf-error-message {
  margin-bottom: 1rem;
}

.lf-retry-button {
  background-color: #e53e3e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.lf-retry-button:hover {
  background-color: #c53030;
}

/* Records grid */
.lf-records-grid {
  display: grid;
  gap: 1rem;
}

.lf-grid-view {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.lf-list-view {
  grid-template-columns: 1fr;
}

/* Client card */
.lf-client-card {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
  cursor: pointer;
}

.lf-client-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transform: translateY(-2px);
  border-color: #cbd5e1;
}

/* Card header */
.lf-card-header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #edf2f7;
  background-color: #f8fafc;
}

.lf-client-name {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  color: #2d3748;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 70%;
}

.lf-folder-id {
  font-size: 0.75rem;
  color: #718096;
  background-color: #edf2f7;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, monospace;
}

/* Card content */
.lf-card-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  flex: 1;
}

.lf-folder-path {
  font-size: 0.875rem;
  color: #4a5568;
  padding-bottom: 0.5rem;
  border-bottom: 1px dashed #edf2f7;
}

.lf-salesforce-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.lf-salesforce-label {
  font-size: 0.75rem;
  color: #718096;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.lf-salesforce-id {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, monospace;
  font-size: 0.875rem;
  color: #3182ce;
  background-color: #ebf8ff;
  padding: 0.375rem 0.5rem;
  border-radius: 4px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Card footer */
.lf-card-footer {
  padding: 0.75rem 1rem;
  font-size: 0.75rem;
  color: #718096;
  background-color: #f8fafc;
  border-top: 1px solid #edf2f7;
}

.lf-created-date {
  display: inline-block;
}

/* List view specific styles */
.lf-list-view .lf-client-card {
  flex-direction: row;
  align-items: center;
}

.lf-list-view .lf-card-header {
  width: 30%;
  border-bottom: none;
  border-right: 1px solid #edf2f7;
  height: 100%;
}

.lf-list-view .lf-card-content {
  width: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid #edf2f7;
  padding: 0.75rem 1rem;
}

.lf-list-view .lf-card-footer {
  width: 20%;
  border-top: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.lf-list-view .lf-folder-path {
  border-bottom: none;
  padding-bottom: 0;
}

/* Modal styles */
.lf-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
  animation: lf-fadeIn 0.2s ease;
}

@keyframes lf-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.lf-modal-container {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  position: relative;
  animation: lf-slideUp 0.3s ease;
}

@keyframes lf-slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.lf-modal-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #a0aec0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.lf-modal-close:hover {
  background-color: #edf2f7;
  color: #4a5568;
}

.lf-modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e2e8f0;
}

.lf-modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 0.25rem 0;
  color: #2d3748;
}

.lf-modal-subtitle {
  font-size: 0.875rem;
  color: #718096;
}

.lf-modal-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.lf-detail-group {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.lf-detail-heading {
  font-size: 1rem;
  font-weight: 600;
  color: #4a5568;
  margin: 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #edf2f7;
}

.lf-detail-row {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.lf-detail-label {
  font-size: 0.75rem;
  color: #718096;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.lf-detail-value {
  font-size: 0.9375rem;
  color: #2d3748;
}

.lf-detail-value.lf-path-value {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, monospace;
  font-size: 0.875rem;
  background-color: #f7fafc;
  padding: 0.5rem;
  border-radius: 4px;
  overflow-wrap: break-word;
  word-break: break-all;
}

.lf-detail-value.lf-salesforce-value {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, monospace;
  color: #3182ce;
  background-color: #ebf8ff;
  display: inline-block;
  padding: 0.375rem 0.5rem;
  border-radius: 4px;
}

.lf-timestamp {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
}

.lf-timestamp .lf-time {
  color: #718096;
  font-size: 0.875rem;
}

.lf-metadata-content {
  background-color: #f7fafc;
  padding: 1rem;
  border-radius: 4px;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, monospace;
  font-size: 0.875rem;
  color: #4a5568;
  white-space: pre-wrap;
  overflow-x: auto;
  margin: 0;
}

.lf-modal-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  border-top: 1px solid #e2e8f0;
  background-color: #f8fafc;
}

.lf-modal-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.lf-modal-button.lf-primary {
  background-color: #4299e1;
  color: white;
  border: none;
}

.lf-modal-button.lf-primary:hover {
  background-color: #3182ce;
}

.lf-modal-button.lf-secondary {
  background-color: white;
  color: #4a5568;
  border: 1px solid #e2e8f0;
}

.lf-modal-button.lf-secondary:hover {
  background-color: #edf2f7;
}

/* Responsive styles */
@media (max-width: 768px) {
  .lf-records-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .lf-records-controls {
    width: 100%;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .lf-search-container {
    width: 100%;
  }

  .lf-search-input {
    width: 100%;
  }

  .lf-list-view .lf-client-card {
    flex-direction: column;
  }

  .lf-list-view .lf-card-header,
  .lf-list-view .lf-card-content,
  .lf-list-view .lf-card-footer {
    width: 100%;
    border-right: none;
  }

  .lf-list-view .lf-card-header {
    border-bottom: 1px solid #edf2f7;
  }

  .lf-list-view .lf-card-content {
    flex-direction: column;
    border-bottom: 1px solid #edf2f7;
  }

  .lf-list-view .lf-card-footer {
    justify-content: flex-start;
  }

  .lf-modal-container {
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;
  }

  .lf-detail-row {
    flex-direction: column;
  }
}

/* Print styles */
@media print {
  .lf-client-records-container {
    background-color: white;
    padding: 0;
  }

  .lf-records-controls {
    display: none;
  }

  .lf-client-card {
    break-inside: avoid;
    page-break-inside: avoid;
    border: 1px solid #e2e8f0;
    margin-bottom: 1rem;
    box-shadow: none;
  }

  .lf-grid-view {
    display: block;
  }

  .lf-modal-overlay {
    display: none;
  }
}

