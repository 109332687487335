/* CSS Variables */
:root {
  --fd-primary-color: #4361ee;
  --fd-primary-hover: #3a56d4;
  --fd-secondary-color: #f8f9fa;
  --fd-text-color: #333;
  --fd-text-light: #6c757d;
  --fd-border-color: #e9ecef;
  --fd-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --fd-radius: 8px;
  --fd-spacing-sm: 8px;
  --fd-spacing-md: 16px;
  --fd-spacing-lg: 24px;
  --fd-spacing-xl: 32px;
  --fd-danger-color: #e63946;
  --fd-danger-hover: #d32f2f;
}

/* Dashboard Container */
.fd-dashboard-container {
  margin: 0 auto;
  padding: var(--fd-spacing-lg);
}

/* Dashboard Header */
.fd-dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--fd-spacing-xl);
}

.fd-dashboard-header h1 {
  font-size: 28px;
  font-weight: 600;
  color: var(--fd-text-color);
}

/* Create Button */
.fd-create-button {
  background-color: var(--fd-primary-color);
  color: white;
  border: none;
  border-radius: var(--fd-radius);
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.fd-create-button:hover {
  background-color: var(--fd-primary-hover);
}

/* Forms Grid */
.fd-forms-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--fd-spacing-lg);
}

/* Form Card */
.fd-form-card {
  background-color: white;
  border-radius: var(--fd-radius);
  box-shadow: var(--fd-shadow);
  padding: var(--fd-spacing-lg);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.fd-form-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.fd-form-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--fd-spacing-md);
}

.fd-form-card-header h2 {
  font-size: 18px;
  font-weight: 600;
  color: var(--fd-text-color);
}

.fd-card-actions {
  display: flex;
  gap: var(--fd-spacing-sm);
}

.fd-form-description {
  color: var(--fd-text-light);
  margin-bottom: var(--fd-spacing-lg);
  font-size: 14px;
}

.fd-form-meta {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: var(--fd-text-light);
  border-top: 1px solid var(--fd-border-color);
  padding-top: var(--fd-spacing-md);
}

.fd-form-elements,
.fd-form-date {
  color: var(--fd-text-light);
}

/* Icon Buttons */
.fd-icon-button {
  color: var(--fd-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  cursor: pointer;
  border: none;
  background: transparent;
}

.fd-view-icon:hover {
  background-color: rgba(67, 97, 238, 0.1);
}

.fd-delete-icon {
  color: var(--fd-danger-color);
}

.fd-delete-icon:hover {
  background-color: rgba(230, 57, 70, 0.1);
}

/* Loading State */
.fd-loading {
  text-align: center;
  padding: var(--fd-spacing-xl);
  font-size: 18px;
  color: var(--fd-text-light);
}

/* Modal Styles */
.fd-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.fd-modal {
  background-color: white;
  border-radius: var(--fd-radius);
  width: 90%;
  max-width: 500px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.fd-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--fd-spacing-md) var(--fd-spacing-lg);
  border-bottom: 1px solid var(--fd-border-color);
}

.fd-modal-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.fd-modal-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--fd-text-light);
}

.fd-modal-body {
  padding: var(--fd-spacing-lg);
}

.fd-confirm-input {
  margin-top: var(--fd-spacing-lg);
}

.fd-confirm-input label {
  display: block;
  margin-bottom: var(--fd-spacing-sm);
  font-size: 14px;
}

.fd-confirm-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--fd-border-color);
  border-radius: var(--fd-radius);
  font-size: 16px;
}

.fd-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: var(--fd-spacing-md);
  padding: var(--fd-spacing-md) var(--fd-spacing-lg);
  border-top: 1px solid var(--fd-border-color);
}

.fd-button {
  padding: 10px 16px;
  border-radius: var(--fd-radius);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s ease;
}

.fd-button-secondary {
  background-color: var(--fd-secondary-color);
  color: var(--fd-text-color);
}

.fd-button-danger {
  background-color: var(--fd-danger-color);
  color: white;
}

.fd-button-danger:hover:not(.fd-button-disabled) {
  background-color: var(--fd-danger-hover);
}

.fd-button-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .fd-dashboard-header {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--fd-spacing-md);
  }

  .fd-forms-grid {
    grid-template-columns: 1fr;
  }
}

