.client-data-page {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  max-width: 900px;
  margin: 0 auto;
  min-height: 100vh;
}

.client-data-page h1 {
  color: #1e293b;
  margin-bottom: 24px;
  font-size: 2.5rem;
  font-weight: 700;
}

.folder-structure {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.folder {
  margin-bottom: 8px;
}

.folder-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}

.folder-header:hover {
  background-color: #f1f5f9;
}

.folder-toggle {
  margin-right: 8px;
  font-size: 12px;
  transition: transform 0.2s ease;
  display: inline-block;
}

.folder-toggle.open {
  transform: rotate(90deg);
}

.folder-icon {
  margin-right: 12px;
  font-size: 16px;
}

.folder-name {
  font-size: 0.95rem;
  color: #334155;
}

.folder-content {
  margin-left: 24px;
  border-left: 1px dashed #cbd5e1;
  padding-left: 12px;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.folder-content.open {
  max-height: 1000px;
  opacity: 1;
  transition: all 0.5s ease-in;
}

.level-0 > .folder-header {
  font-weight: 600;
  color: #0f172a;
}

.level-1 > .folder-header {
  font-weight: 500;
}

.level-2 > .folder-header,
.level-3 > .folder-header {
  font-size: 0.9em;
}

