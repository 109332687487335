.share-url-card {
    width: 100%;
    max-width: 400px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #ffffff;
  }
  
  .share-url-content {
    padding: 12px;
  }
  
  .share-url-label {
    font-size: 14px;
    font-weight: 500;
    color: #64748b;
    margin-bottom: 8px;
  }
  
  .share-url-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .share-url-text-container {
    flex: 1;
    background-color: #f1f5f9;
    border-radius: 6px;
    padding: 8px 12px;
    overflow: hidden;
  }
  
  .share-url-text {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    color: #334155;
  }
  
  .share-url-button {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid #e2e8f0;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
  }
  
  .share-url-button:hover {
    background-color: #f8fafc;
    border-color: #cbd5e1;
  }
  
  .share-url-button:focus {
    outline: 2px solid #3b82f6;
    outline-offset: 2px;
  }
  
  .share-url-tooltip {
    position: absolute;
    bottom: -40px;
    right: 0;
    background-color: #1e293b;
    color: white;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
    white-space: nowrap;
    pointer-events: none;
  }
  
  .share-url-button:hover .share-url-tooltip {
    opacity: 1;
    visibility: visible;
  }
  
  .share-url-icon {
    width: 16px;
    height: 16px;
  }
  
  .share-url-success-icon {
    color: #10b981;
  }
  
  /* Dark mode support */
  .dark .share-url-card {
    background-color: #1e293b;
    border-color: #334155;
  }
  
  .dark .share-url-label {
    color: #94a3b8;
  }
  
  .dark .share-url-text-container {
    background-color: #0f172a;
  }
  
  .dark .share-url-text {
    color: #e2e8f0;
  }
  
  .dark .share-url-button {
    background-color: #1e293b;
    border-color: #334155;
  }
  
  .dark .share-url-button:hover {
    background-color: #334155;
  }
  
  