.trello-reports {
    border-radius: .5rem;
    border-top-left-radius: 0;
    box-shadow: 0 4px 12px #0000001a;
    padding: 2rem;
}

.trello-reports-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.card-name {
    width: 300px !important;
}

.filters-open {
    width: 150px;
}


.filter-container-s {
    position: relative;
    display: inline-block;
  }
  
  .filter-button {
    padding: 8px 12px;
    border: none;
    background: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .tooltip {
    position: absolute;
    top: 100%;
    transform: translateX(-43%);
    background: white;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
    z-index: 10;
  }
  
  .tooltip-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .tooltip-content label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
  
  
  .apply-button {
    padding: 6px;
    background: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .pag-btn {
    min-width: 0px;
    width: 36px;
    height: 36px;
    display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        box-sizing: border-box;
        text-align: center;
        font-size: 1.5rem;
        box-shadow: none;
        text-transform: none;
        font-weight: 500;
        letter-spacing: 0px;
        color: rgb(11, 14, 20);
        background-color: rgba(245, 246, 250, 0.3);
        width: 2.5rem;
        height: 2.5rem;
        outline: 0px;
        margin: 0px;
        text-decoration: none;
        flex: 0 0 auto;
        padding: 8px;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        border-image: initial;
        border-color: rgb(218, 222, 231);
    }
  

  .trello-cards-container {
    display: grid;
    gap: 20px;    
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  .header-filters-right {
    display: flex;
    gap: 10px;
  }

  .header-filters-right select {
    border: 1px solid #bebebe;
    border-radius: 4px;
    color: #252525;
    cursor: pointer;
    padding: 5px;
  }

  /* Base styles */
:root {
  --primary-color: #4f46e5;
  --primary-hover: #4338ca;
  --background-color: #ffffff;
  --border-color: #e5e7eb;
  --text-color: #111827;
  --text-muted: #6b7280;
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --radius-sm: 0.25rem;
  --radius-md: 0.375rem;
  --radius-lg: 0.5rem;
  --radius-xl: 0.75rem;
}

/* App container */
.app-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

@media (min-width: 768px) {
  .app-container {
    padding: 6rem;
  }
}

/* App content */
.app-content {
  width: 100%;
  max-width: 64rem;
  margin-bottom: 2rem;
}

.app-title {
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.app-description {
  color: var(--text-muted);
  margin-bottom: 1.5rem;
}

/* Button */
.app-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 1.25rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--radius-md);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.15s ease;
}

.app-button:hover {
  background-color: var(--primary-hover);
}

/* Popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  padding: 1rem;
}

.popup-container {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
}

.popup-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  color: var(--text-color);
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.15s ease;
}

.popup-close:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

/* Active filters */
.active-filters {
  margin-top: 2rem;
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
}

.active-filters-title {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.active-filters-code {
  background-color: #f3f4f6;
  padding: 1rem;
  border-radius: var(--radius-md);
  overflow: auto;
  font-family: monospace;
  font-size: 0.875rem;
}

