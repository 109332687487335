.views-container {
    background: #ededed;
    padding: 8px;
    padding-bottom: 0px;
    overflow-x: scroll !important;
    white-space: nowrap; /* Prevent text from wrapping */
    scrollbar-width: none;
}

.views-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari, Edge */
  }

.one-view {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 5px 10px;
}

.views-container button {
    background-color: initial;
    border: none;
    font-size: 15px;
    padding: 0px;
    display: grid;
    align-items: center;
    justify-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.views-container button .close-icon {
    font-size: 16px;
}

.view-separation {
    height: 20px;
    background: #b4b4b4;
    width: 1px;
}

.close-btn {
    border-radius: 50%;
    height: 20px;
    width: 20px;
}

.add-view {
    border-radius: 50%;
    padding: 5px;
    width: 30px;
    height: 30px;
}

.add-view:hover {
    background: #f6f6f6;  
}

.close-btn:hover {
    background: #f6f6f6;  
}

.icon-add {
    font-size: 20px;
}