:root {
    --primary-color: #3498db;
    --secondary-color: #2ecc71;
    --background-color: #f5f7fa;
    --text-color: #34495e;
    --error-color: #e74c3c;
    --card-background: #ffffff;
    --border-color: #e0e0e0;
  }
  
  .error-log-container {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    margin: 2rem auto;
    padding: 0 1rem;
    max-height: 500px;
    overflow-x: scroll;
  }
  
  .error-log-title {
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }
  
  .error-log {
    background-color: var(--card-background);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
  }
  
  .error-log:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .error-log-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .error-log-header:hover {
    background-color: #2980b9;
  }
  
  .error-log-id {
    font-weight: bold;
    font-size: 1.1rem;
  }
  
  .error-log-user {
    flex: 1;
    margin: 0 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .error-log-method {
    font-weight: bold;
    color: var(--secondary-color);
    background-color: rgba(46, 204, 113, 0.2);
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    margin-right: 0.5rem;
  }
  
  .error-log-url {
    color: #bdc3c7;
    margin-right: 1rem;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .error-log-date {
    font-size: 0.9em;
    color: #ecf0f1;
  }
  
  .error-log-details {
    padding: 1.5rem;
    border-top: 1px solid var(--border-color);
    animation: fadeIn 0.3s ease-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .error-log-details-text {
    margin: 0.5rem 0;
  }
  
  .error-log-details-code {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 4px;
    overflow-x: auto;
    font-size: 0.9em;
    border: 1px solid var(--border-color);
    white-space: pre-wrap;
    word-break: break-all;
  }
  
  .error-message {
    color: var(--error-color);
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .stack-trace {
    margin-top: 1rem;
  }
  
  .stack-trace-header {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .headers-section {
    margin-top: 1rem;
  }
  
  .headers-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .expand-icon {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
  }
  
  .expanded .expand-icon {
    transform: rotate(180deg);
  }
  
  