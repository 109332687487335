.select-action {
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
}
.goals-container {
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(300px , 1fr));
    gap: 10px;
}
.goal-card {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #e0e0e0;
  }
  
  .goal-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .goal-content {
    margin-bottom: 16px;
  }
  
  .goal-action-2 {
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 12px 0;
    color: #000000;
  }
  
  .goal-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .goal-count-wrapper {
    font-size: 16px;
    color: #000000;
    margin: 0 0 8px 0;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 4px;
    display: inline-block;
  }
  
  .goal-count-wrapper span {
    font-weight: 600;
  }
  
  .goal-description {
    font-size: 14px;
    color: #333333;
    margin: 0;
    line-height: 1.5;
  }
  
  .goal-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .btn-small {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .update-btn {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    margin-right: 8px;
  }
  
  .update-btn:hover {
    background-color: #000000;
    color: #ffffff;
  }
  
  .delete-btn {
    background-color: #000000;
    color: #ffffff;
  }
  
  .delete-btn:hover {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
  }
  
  .btn-text {
    position: relative;
    overflow: hidden;
  }
  
  .btn-text::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  .btn-small:hover .btn-text::after {
    transform: scaleX(1);
  }
  
  @media (max-width: 600px) {
    .goal-details {
      flex-direction: column;
    }
  }
  