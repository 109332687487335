:root {
    --txn-primary-color: #2563eb;
    --txn-error-color: #ef4444;
    --txn-error-bg: #fee2e2;
    --txn-text-primary: #111827;
    --txn-text-secondary: #6b7280;
    --txn-bg-primary: #ffffff;
    --txn-bg-secondary: #f3f4f6;
    --txn-border-color: #e5e7eb;
    --txn-border-radius: 0.75rem;
    --txn-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }
  
  .txn-card {
    width: 100%;
    background: var(--txn-bg-primary);
    border-radius: var(--txn-border-radius);
    box-shadow: var(--txn-shadow);
    overflow: hidden;
    border: 1px solid var(--txn-border-color);
  }
  
  .txn-card__banner {
    background: var(--txn-error-color);
    color: white;
    padding: 0.375rem 1rem;
    font-size: 0.75rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .txn-card__banner-left {
    display: flex;
    align-items: center;
    gap: 0.375rem;
  }
  
  .txn-card__content {
    padding: 1rem;
  }
  
  .txn-card__amount-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .txn-card__amount {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--txn-primary-color);
    line-height: 1.2;
    margin: 0px;
  }
  
  .txn-card__name-section {
    margin-bottom: 1rem;
  }
  
  .txn-card__name {
    font-weight: 600;
    font-size: 1rem;
    color: var(--txn-text-primary);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .txn-card__dates-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .txn-card__date-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .txn-card__details {
    background: var(--txn-bg-secondary);
    border-radius: 0.5rem;
    padding: 0.75rem;
  }
  
  .txn-card__detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0;
  }
  
  .txn-card__detail-row:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  
  .txn-card__label {
    font-size: 0.75rem;
    color: var(--txn-text-secondary);
    margin-bottom: 0.25rem;
    margin: 0px;
  }
  
  .txn-card__value {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--txn-text-primary);
    margin: 0px;
  }
  
  .txn-card__value--past-due {
    color: var(--txn-error-color);
  }
  
  .txn-card__icon {
    width: 1rem;
    height: 1rem;
    color: currentColor;
  }
  
  /* Responsive adjustments */
  @media (max-width: 640px) {
    .txn-card {
      max-width: 100%;
    }
  
    .txn-card__dates-grid {
      grid-template-columns: 1fr;
      gap: 0.5rem;
    }
  }
  
  